body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.logoWrapper {
  width: 100px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.formWrapper {
  margin-top: 10px;
  margin-bottom: 10px;
}

.listIcon {
  min-width: 30px;
  margin-right: 10px;
}

:root {
  --theme-color: #0046ff;
  --font-color: #00005a;
  --menu-color: #9b9ab6;
  --background-default: #fafafa; /* Near to white */
  --background-paper: #f2f2f2; /* Grayish */
  --text-primary: #00005a; /* Primary text color */
}

.iconFont {
  width: 20px;
}

.avatarLogin {
  position: absolute;
  bottom: 0px;
}

.loginCTA {
  background-color: var(--theme-color) !important;
}

.themeBackground {
  background-color: var(--theme-color) !important;
}

.forgotPassword {
  color: var(--theme-color) !important;
}

.activeGreen {
  color: var(--theme-color) !important;
}

.themeColor {
  color: var(--theme-color) !important;
}

.menuoptionColor {
  color: var(--menu-color) !important;
}

.loginHeader {
  display: block;
  text-align: left;
  width: 100%;
  font-size: 16px;
  margin-bottom: 25px !important;
}

.loginLabel {
  width: '100%';
  left: '-11px';
}

.bodyWrapper {
  background: var(--background-paper); /* Grayish */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

body {
  background: var(--background-default); /* Near to white */
}

.themeBadge {
  background-color: var(--theme-color);
  color: #fff;
  border-radius: '5px';
}

.formio-form > div > nav > ul.pagination .page-item.active .page-link {
  background-color: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
}

.btn-primary {
  background-color: var(--theme-color) !important;
  border-color: var(--theme-color) !important;
}

.formio-form > div > nav > ul.pagination .page-link {
  color: var(--theme-color) !important;
  background: var(--background-default);
}

.formio-form > div > nav > ul.pagination .page-item.active .page-link {
  color: #fff !important;
}

.formio-form input[type='checkbox'],
.formio-form input[type='radio'] {
  accent-color: var(--theme-color) !important;
}

.formio-form .badge.badge-secondary {
  background-color: var(--theme-color) !important;
}

.nav-item a {
  color: var(--text-primary) !important;
}

.leftPaneWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.leftPaneInnerWrapper {
  padding: 15px;
}

.formWrapper {
  padding: 12px;
}

.dragAndDrop {
  display: flex;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 300px;
  border: 1px dotted;
}

.MuiTablePagination-selectLabel,
.MuiTablePagination-displayedRows {
  margin-bottom: 0;
}

.wizard-page > .formio-component-button > .btn {
  float: right;
  margin-left: 10px;
}

.wizard-page > .formio-component-button {
  float: right;
}

.formio-wizard-nav-container {
  justify-content: flex-end;
}

.formio-form {
  color: var(--text-primary) !important;
}

.formio-component-label {
  color: var(--text-primary) !important;
}

.formio-component-input {
  color: var(--text-primary) !important;
}

.formio-component-input::placeholder {
  color: #aaa;
}

.formio-error-message {
  color: red;
}

.formio-dialog.formio-dialog-theme-default .formio-dialog-content {
  background-color: var(--background-paper);
}
.card-body {
  background-color: var(--background-paper);
}

.formio-button {
  color: white;
  background-color: var(--theme-color) !important;
}

.formio-component-textfield .form-control {
  color: var(--text-primary) !important;
}

.formio-component-select .choices__inner {
  color: var(--text-primary) !important;
}

.formio-component input {
  color: var(--text-primary) !important;
  background-color: var(--background-default); /* Grayish */
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
}

.field-wrapper .field-content input {
  color: var(--text-primary) !important;
}

.formio-component input::placeholder {
  color: #9e9e9e;
  opacity: 1;
}

.formio-component input:focus {
  outline: none;
  border-color: var(--theme-color) !important;
  background-color: var(--background-default); /* Near to white */
}

.formio-component input:disabled {
  color: #b0b0b0;
  background-color: var(--background-paper); /* Grayish */
  border-color: #ddd;
}

.formio-component.has-error input {
  border-color: red;
  background-color: var(--background-default);
}
.flatpickr-day {
  background-color: var(--background-default) !important;
  color: var(--text-primary) !important;
}

.flatpickr-day.prevMonthDay {
  background-color: #b0b0b0 !important;
  color: var(--text-primary) !important;
}

.flatpickr-calendar {
  background-color: var(--background-paper) !important;
}
.form-control.selection.dropdown {
  color: var(--text-primary) !important;
}

.flatpickr-day.flatpickr-disabled {
  background-color: var(--background-paper) !important;
}

.formio-component.formio-component-datetime input {
  color: var(--text-primary) !important;
  background-color: var(--background-paper); /* Grayish */
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 16px;
}

.formio-component.formio-component-datetime input::placeholder {
  color: #9e9e9e;
}

.formio-component.formio-component-datetime input:focus {
  outline: none;
  border-color: #007bff;
  background-color: var(--background-default); /* Near to white */
}

.formio-component.formio-component-datetime input:disabled {
  color: #b0b0b0;
  background-color: var(--background-paper); /* Grayish */
  border-color: #ddd;
}

.flatpickr-calendar {
  font-family: Arial, sans-serif;
  color: var(--text-primary) !important;
}

.flatpickr-day.selected {
  background-color: var(--theme-color) !important;
  color: #ffffff !important;
}

.flatpickr-day:hover {
  background-color: var(--background-paper); /* Grayish */
  color: #000000;
}

.flatpickr-day.today {
  border: 1px solid #ff9800;
  color: #ff9800;
}

.flatpickr-current-month select {
  color: var(--text-primary) !important;
}

.flatpickr-current-month .numInput {
  color: var(--text-primary) !important;
}

.flatpickr-weekday {
  color: var(--text-primary) !important;
}

.flatpickr-day {
  color: var(--text-primary) !important;
}

.flatpickr-day.prevMonthDay {
  color: var(--text-primary) !important;
}

.flatpickr-day.flatpickr-disabled {
  color: #f2f2f2 !important;
}

.formio-component-datagrid table {
  color: var(--text-primary) !important;
}

.fa.fa-calendar {
  color: var(--text-primary) !important;
}

.formio-component-textarea textarea {
  color: var(--text-primary) !important;
}

.MuiDataGrid-toolbarContainer {
  background: var(--background-paper) !important;
}

.MuiButtonBase-root .MuiListItemIcon-root .MuiSvgIcon-root {
  fill: 'palette.primary.main';
}

/* Form Container */
.formio-form {
  background-color: var(--background-default); /* Grayish */
  color: var(--text-primary); /* Light text */
  padding: 20px;
  border-radius: 0.25rem;
}

/* Form Labels */
.formio-component-label {
  color: var(--text-primary);
}

/* Textfields and Textareas */
.formio-component-textfield input,
.formio-component-textarea textarea {
  background-color: var(--background-default); /* Grayish */
  color: var(--text-primary);
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.formio-component-textfield input:focus,
.formio-component-textarea textarea:focus {
  background-color: var(--background-default); /* Near to white */
  color: var(--text-primary);
  border-color: #6c757d;
}

/* Select Component */
.formio-component-select select {
  background-color: var(--background-default); /* Grayish */
  color: var(--text-primary);
  border: 1px solid #ced4da;
}

/* Button */
.formio-component-button .btn {
  background-color: var(--theme-color);
  color: #fff;
  border: none;
}

/* Targeting Formio Choice widget select boxes */
.choices.form-group.formio-choices {
  background-color: var(--background-default); /* Grayish */
  color: var(--text-primary); /* Primary text color */
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 10px;
}
.choices.form-group.formio-choices .selection.dropdown {
  background-color: var(--background-default);
}

/* When the Formio select dropdown is focused */
.choices.form-group.formio-choices:focus-within {
  background-color: var(--background-default); /* Near to white */
  border-color: #6c757d;
  outline: none;
}

/* Dropdown items */
.choices__list--dropdown .choices__item {
  background-color: var(--background-paper); /* Grayish */
  color: var(--text-primary); /* Primary text color */
  padding: 10px;
}

/* Highlighted/active item in dropdown */
.choices__list--dropdown .choices__item--highlighted {
  background-color: var(--background-default); /* Theme color for selected item */
  color: #fff; /* White text for better contrast */
}

/* Disabled select inputs */
.choices.form-group.formio-choices input:disabled {
  background-color: var(--background-paper); /* Grayish */
  color: #b0b0b0;
  border-color: #ddd;
}

/* Dropdown arrow icon (if applicable) */
.choices__inner {
  background-color: var(--background-paper); /* Grayish */
  color: var(--text-primary); /* Primary text color */
}

/* Placeholder text in the select input */
.choices__placeholder {
  color: #9e9e9e;
}

.component-btn-group .component-settings-button {
  background: #b0b0b0;
}

.component-settings-button i.fa {
  color: #f2f2f2;
}
.choices[data-type*='select-one']::after {
  border-color: #f2f2f2 transparent transparent transparent !important;
}
.card-header {
  background-color: var(--background-paper);
  border-bottom: 1px solid #b0b0b0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: var(--background-default);
  color: var(--text-primary) !important;
}

.formio-dialog *,
.formio-dialog :after,
.formio-dialog :before {
  color: var(--text-primary) !important;
}
.formio-dialog .tippy-box .tippy-content {
  color: #ffffff !important;
  background-color: #000000 !important;
}
.formio-dialog .tippy-box .tippy-content a {
  color: #b0b0b0 !important;
}
.ace_editor,
.ace_editor div,
.ace_editor span {
  color: #000000 !important;
}
.tippy-box[data-placement^='right'] > .tippy-arrow:before {
  color: #000000 !important;
}
.formio-dialog .btn-danger,
.formio-dialog .btn-secondary,
.formio-dialog .btn-success,
.formio-dialog .btn-primary {
  color: #ffffff !important;
}
.formio-dialog .btn-danger i.fa::before,
.formio-dialog .btn-secondary i.fa::before,
.formio-dialog .btn-success i.fa::before,
.formio-dialog .btn-primary i.fa::before {
  color: #ffffff !important;
}
.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: #b0b0b0;
}
.choices[data-type*='select-one'] .choices__input {
  background-color: #b0b0b0;
  color: var(--text-primary) !important;
}
.btn.btn-block.builder-group-button {
  color: var(--text-primary) !important;
}
.formio-dialog .input-group-text {
  background-color: #b0b0b0;
}
.card.card-body.bg-light.bg-light {
  background-color: #b0b0b0 !important;
}

.formio-dialog .list-group-item.list-group-header {
  background: #b0b0b0 !important;
}
.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  fill: var(--text-primary) !important;
}
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  fill: var(--text-primary) !important;
}
.list-group-item {
  background-color: var(--background-default);
}
.custom-popper-class #smart-search-listbox,
#smart-search-listbox li div,
#smart-search-listbox li .css-pixpsc {
  background-color: var(--background-default);
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: 0 0 0px 1000px var(--background-default) inset;
  -webkit-text-fill-color: var(--text-primary);
  transition: background-color 5000s ease-in-out 0s;
}

/* Autofill while focused */
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px var(--background-default) inset;
  -webkit-text-fill-color: var(--text-primary);
}
.MuiTabs-flexContainer {
  border-bottom: solid thin #b0b0b0;
}
.MuiDivider-root.MuiDivider-fullWidth {
  border-color: #b0b0b0;
}
.MuiPaper-root.MuiDrawer-paper {
  border-right: solid thin #b0b0b0;
}
#SelectLanguage {
  padding-right: 29px;
  padding-bottom: 10px;
  padding-top: 10px;
}
.form-text.error,
.MuiFormLabel-asterisk {
  color: red !important;
}
