/* App.css */

.app-container {
  height: 100vh; /* Set the container height to cover the viewport */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vh;
}

.svg-container {
  width: 100%; /* Adjust width as needed */
  height: 300px; /* Adjust height as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
